<template>
  <Sheet
    :value="value"
    width="720px"
    no-padding
    has-footer
    @input="closeSettings"
  >
    <!-- title -->

    <template #title>
      {{ _activeTab }} Settings - {{ field.label || field.type }}
    </template>

    <!-- ... -->

    <!-- form -->

    <template #default>
      <div id="field-settings" class="row">
        <!-- ... -->

        <div class="content">
          <ValidationObserver ref="form">
            <!-- general settings -->

            <GeneralSettings
              v-show="activeTab === 'GENERAL'"
              :settings-for="settingsFor"
              :field-type="field.type"
              :label.sync="label"
              :size.sync="size"
              :url.sync="url"
              :visibility.sync="visibility"
              :hide-label.sync="hideLabel"
              :placeholder.sync="placeholder"
              :tooltip.sync="tooltip"
              :divider-type.sync="dividerType"
              :panels="panels"
              :field-id="field.id"
            />

            <!-- ... -->

            <!-- specific settings -->

            <SpecificSettings
              v-show="activeTab === 'SPECIFIC'"
              :settings-for="settingsFor"
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :field-type="field.type"
              :options-type.sync="optionsType"
              :master-form-id.sync="masterFormId"
              :master-form-column.sync="masterFormColumn"
              :master-form-parent-column.sync="masterFormParentColumn"
              :repository-id.sync="repositoryId"
              :repository-field.sync="repositoryField"
              :repository-field-parent.sync="repositoryFieldParent"
              :predefined-table.sync="predefinedTable"
              :predefined-table-column.sync="predefinedTableColumn"
              :custom-options.sync="customOptions"
              :separate-options-using.sync="separateOptionsUsing"
              :allow-to-add-new-options.sync="allowToAddNewOptions"
              :options-per-line.sync="optionsPerLine"
              :default-value.sync="defaultValue"
              :custom-default-value.sync="customDefaultValue"
              :auto-generate-value.sync="autoGenerateValue"
              :allow-multiple-files.sync="allowMultipleFiles"
              :show-column-total.sync="showColumnTotal"
              :rating-icon.sync="ratingIcon"
              :rating-icon-count.sync="ratingIconCount"
              :allow-half-rating.sync="allowHalfRating"
              :allow-multiple-signatures.sync="allowMultipleSignatures"
              :table-columns.sync="tableColumns"
              :table-row-choose-type.sync="tableRowChooseType"
              :table-rows-type.sync="tableRowsType"
              :table-fixed-row-count.sync="tableFixedRowCount"
              :qr-value.sync="qrValue"
              :matrix-columns.sync="matrixColumns"
              :matrix-rows.sync="matrixRows"
              :matrix-type.sync="matrixType"
              :matrix-type-settings.sync="matrixTypeSettings"
              :fib-fields="fibFields"
              :minimum="minimum"
              :maximum="maximum"
              :tab-list.sync="tabList"
              :popup-trigger-type.sync="popupTriggerType"
              :secondary-panel.sync="secondaryPanel"
              :mapped-field-id.sync="mappedFieldId"
              :mapped-column-id.sync="mappedColumnId"
              :nested-list-max-level.sync="nestedListMaxLevel"
              :nested-list-field-type.sync="nestedListFieldType"
              :nested-list-type-settings.sync="nestedListTypeSettings"
              :nested-list-items-per-line.sync="nestedListItemsPerLine"
              :login-type.sync="loginType"
              :additional-login-types.sync="additionalLoginTypes"
              :field-id="field.id"
              :master-form-columns="masterFormColumns"
              :parent-date-field.sync="parentDateField"
              :parent-fields-days.sync="parentFieldsDays"
              :master-form-table-columns.sync="masterFormTableColumns"
              :master-table-columns-copy="masterTableColumnsCopy"
              :parent-option-field.sync="parentOptionField"
              :date-field-option-settings="dateFieldOptionSettings"
              :is-search-field.sync="isSearchField"
              :has-same-form.sync="hasSameForm"
              :search-form-id.sync="searchFormId"
              :master-form-sync-settings="masterFormSyncSettings"
              :master-sync-field.sync="masterSyncField"
              :form-sync-field.sync="formSyncField"
              @fib-settings="showFibFieldSettings"
              @column-settings="showColumnSettings"
              @matrix-type-settings="showMatrixTypeSettings"
              @nested-list-type-settings="showNestedListTypeSettings"
            />
            <!-- :master-form-table-columns.sync="masterFormTableColumns"
             :master-table-columns-copy="masterTableColumnsCopy" -->

            <!-- ... -->

            <!-- validation -->

            <ValidationSettings
              v-show="activeTab === 'VALIDATION'"
              :settings-for="settingsFor"
              :field-type="field.type"
              :field-rule.sync="fieldRule"
              :content-rule.sync="contentRule"
              :minimum.sync="minimum"
              :maximum.sync="maximum"
              :allowed-file-types.sync="allowedFileTypes"
              :max-file-size.sync="maxFileSize"
              :panels="panels"
              :custom-options.sync="customOptions"
              :field-id="field.id"
              :enable-settings.sync="enableSettings"
              :master-form-id.sync="masterFormId"
              :master-form-column.sync="masterFormColumn"
              :date-range.sync="dateRange"
              :time-range.sync="timeRange"
              :maxi-days.sync="maxiDays"
              :mini-days.sync="miniDays"
              :maxi-time.sync="maxiTime"
              :mini-time.sync="miniTime"
              :date-error="dateError"
              :time-error="timeError"
              :assign-other-controls.sync="assignOtherControls"
              :document-expiry-field.sync="documentExpiryField"
              :mandatory-settings.sync="mandatorySettings"
              :answer-indicator.sync="answerIndicator"
              :readonly-settings.sync="readonlySettings"
              :time-format.sync="timeFormat"
              :range-type.sync="rangeType"
              :minimum-number-field.sync="minimumNumberField"
              :maximum-number-field.sync="maximumNumberField"
              :table-field-id="tableFieldId"
            />

            <!-- ... -->

            <!-- AI Settings -->

            <AISettings
              v-show="activeTab === 'AI_DATAOPS'"
              :form-control-validate="formControlValidate"
              :image-file-validate="imageFileValidate"
              :file-data-validate.sync="fileDataValidate"
              :panels="panels"
              :field-id="field.id"
            />

            <!-- ... -->
          </ValidationObserver>
        </div>

        <!-- ... -->

        <!-- tabs -->

        <div class="tabs">
          <BaseActionButton
            v-for="tab in tabs"
            :key="tab.id"
            v-tooltip.left="tab.label"
            :is-flat="activeTab !== tab.value"
            :icon="tab.icon"
            :color="activeTab === tab.value ? tabColor : 'gray'"
            class="q-mb-md"
            @click="activeTab = tab.value"
          />
        </div>

        <!-- ... -->
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton
        label="cancel"
        is-flat
        :color="tabColor"
        class="q-mr-sm"
        @click="closeSettings"
      />

      <BaseButton label="save" :color="tabColor" @click="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { cloneDeep, isEmpty, lowerCase } from "lodash-es";
import Sheet from "@/components/common/popup/Sheet.vue";
import GeneralSettings from "./components/GeneralSettings.vue";
import SpecificSettings from "./components/specific-settings/SpecificSettings.vue";
import ValidationSettings from "./components/ValidationSettings.vue";
import { form } from "@/api/factory.js";
import AISettings from "./components/AISettings.vue";

export default {
  name: "FieldSettings",

  components: {
    Sheet,
    ValidationObserver,
    GeneralSettings,
    SpecificSettings,
    ValidationSettings,
    AISettings,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      default: () => {},
    },

    settingsFor: {
      type: String,
      default: "NORMAL",
      validator(value) {
        return [
          "NORMAL",
          "FILL_IN_THE_BLANKS",
          "TABLE",
          "MATRIX",
          "NESTED_LIST",
          "TEXT_BUILDER",
        ].includes(value);
      },
    },

    panels: {
      type: Array,
      default: () => [],
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    tableFieldId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL",
          icon: "eva-options-2-outline",
        },
        {
          id: this.$nano.id(),
          label: "specific",
          value: "SPECIFIC",
          icon: "mdi-code-tags-check",
        },
        {
          id: this.$nano.id(),
          label: "validation",
          value: "VALIDATION",
          icon: "mdi-function-variant",
        },
      ],
      activeTab: "GENERAL",

      // general settings

      hideLabel: false,
      label: "",
      size: "",
      visibility: "",
      placeholder: "",
      tooltip: "",
      dividerType: "",
      url: "",
      // ...

      // specific settings

      optionsType: "",
      masterFormId: 0,
      masterFormColumn: "",
      masterFormParentColumn: "",
      repositoryId: 0,
      repositoryField: "",
      repositoryFieldParent: "",
      predefinedTable: "",
      predefinedTableColumn: "",
      customOptions: "",
      separateOptionsUsing: "",
      allowToAddNewOptions: false,
      optionsPerLine: 0,
      defaultValue: "",
      customDefaultValue: "",
      autoGenerateValue: {
        prefix: "Form",
        suffix: "DATE_TIME",
      },
      showColumnTotal: false,
      allowMultipleFiles: false,
      ratingIcon: "",
      ratingIconCount: 5,
      allowHalfRating: false,
      allowMultipleSignatures: false,
      tableColumns: [],
      tableRowChooseType: "",
      tableRowsType: "",
      tableFixedRowCount: 0,
      qrValue: false,
      matrixColumns: [],
      matrixRows: [],
      matrixType: "",
      matrixTypeSettings: {},
      tabList: [],
      popupTriggerType: "",
      secondaryPanel: "",
      mappedFieldId: "",
      mappedColumnId: "",
      nestedListMaxLevel: 0,
      nestedListFieldType: "SHORT_TEXT",
      nestedListItemsPerLine: [],
      nestedListTypeSettings: {},
      loginType: "",
      additionalLoginTypes: [],
      parentDateField: "",
      parentFieldsDays: 0,
      masterFormTableColumns: [],
      parentOptionField: "",
      dateFieldOptionSettings: [],
      isSearchField: "NO",
      hasSameForm: "YES",
      searchFormId: "",
      masterFormSyncSettings: [],
      masterSyncField: "",
      formSyncField: [],

      // ...

      // validation settings

      fieldRule: "",
      contentRule: "",
      minimum: "",
      maximum: "",
      allowedFileTypes: [],
      maxFileSize: 0,
      enableSettings: [],
      dateRange: "NONE",
      timeRange: "NONE",
      maxiDays: 0,
      miniDays: 0,
      maxiTime: 0,
      miniTime: 0,
      assignOtherControls: [],
      mandatorySettings: [],
      answerIndicator: "NO",
      readonlySettings: [],
      documentExpiryField: "",
      timeFormat: "",
      rangeType: "",
      maximumNumberField: "",
      minimumNumberField: "",

      // ...

      // fib fields

      fibFields: [],

      // ...

      masterFormColumns: [],
      dateError: "",
      timeError: "",
      masterTableColumnsCopy: [],
      // AI settings

      formControlValidate: {
        optionsType: "",
        masterFormId: 0,
        masterFormColumn: [],
        formFields: "",
        existingFormFields: [],
        conditionFields: [],
      },
      fileDataValidate: [],
      imageFileValidate: {
        optionsType: "",
        formFields: [],
      },
    };
  },

  computed: {
    _activeTab() {
      return lowerCase(this.activeTab);
    },

    tabColor() {
      return this.settingsFor === "NORMAL" ? "primary" : "secondary";
    },

    getCustomDefaultValue() {
      switch (this.field.type) {
        case "MULTI_SELECT":
        case "MULTIPLE_CHOICE":
          return [];
        default:
          return "";
      }
    },
  },

  watch: {
    field: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEmpty(this.field)) {
          // general settings

          this.hideLabel = this.field.settings.general.hideLabel;
          this.label = this.field.label;
          this.size = this.field.settings.general.size;
          this.visibility = this.field.settings.general.visibility;
          this.placeholder = this.field.settings.general.placeholder;
          this.tooltip = this.field.settings.general.tooltip;
          this.dividerType = this.field.settings.general.dividerType;
          this.url = this.field.settings.general.url || "";

          // ...

          // specific settings

          this.optionsType = this.field.settings.specific.optionsType;
          this.masterFormId = this.field.settings.specific.masterFormId;
          this.masterFormColumn = this.field.settings.specific.masterFormColumn;
          this.masterFormParentColumn =
            this.field.settings.specific.masterFormParentColumn;
          this.repositoryId = this.field.settings.specific.repositoryId || 0;
          this.repositoryField =
            this.field.settings.specific.repositoryField || "";
          this.repositoryFieldParent =
            this.field.settings.specific.repositoryFieldParent || "";
          this.predefinedTable = this.field.settings.specific.predefinedTable;
          this.predefinedTableColumn =
            this.field.settings.specific.predefinedTableColumn;
          this.customOptions = this.field.settings.specific.customOptions;
          this.separateOptionsUsing =
            this.field.settings.specific.separateOptionsUsing;
          this.allowToAddNewOptions =
            this.field.settings.specific.allowToAddNewOptions;
          this.optionsPerLine = this.field.settings.specific.optionsPerLine;
          this.defaultValue = this.field.settings.specific.defaultValue;
          this.customDefaultValue =
            this.field.settings.specific.customDefaultValue;
          this.autoGenerateValue =
            this.field.settings.specific.autoGenerateValue;
          this.showColumnTotal = this.field.settings.specific.showColumnTotal;
          this.allowMultipleFiles =
            this.field.settings.specific.allowMultipleFiles;
          this.ratingIcon = this.field.settings.specific.ratingIcon;
          this.ratingIconCount = this.field.settings.specific.ratingIconCount;
          this.allowHalfRating = this.field.settings.specific.allowHalfRating;
          this.allowMultipleSignatures =
            this.field.settings.specific.allowMultipleSignatures;
          this.tableColumns = this.field.settings.specific.tableColumns;
          this.tableRowChooseType =
            this.field.settings.specific.tableRowChooseType;
          this.tableRowsType = this.field.settings.specific.tableRowsType;
          this.tableFixedRowCount =
            this.field.settings.specific.tableFixedRowCount;
          this.qrValue = this.field.settings.specific.qrValue;
          this.matrixColumns = this.field.settings.specific.matrixColumns;
          this.matrixRows = this.field.settings.specific.matrixRows;
          this.matrixType = this.field.settings.specific.matrixType;
          this.matrixTypeSettings =
            this.field.settings.specific.matrixTypeSettings;
          this.fibFields = this.field.settings.specific.fibFields;
          this.tabList = this.field.settings.specific.tabList;
          this.popupTriggerType = this.field.settings.specific.popupTriggerType;
          this.secondaryPanel = this.field.settings.specific.secondaryPanel;
          this.mappedFieldId = this.field.settings.specific.mappedFieldId;
          this.mappedColumnId = this.field.settings.specific.mappedColumnId;
          this.nestedListMaxLevel =
            this.field.settings.specific.nestedListMaxLevel;
          this.nestedListFieldType =
            this.field.settings.specific.nestedListFieldType;
          this.nestedListTypeSettings =
            this.field.settings.specific.nestedListTypeSettings;
          this.nestedListItemsPerLine =
            this.field.settings.specific.nestedListItemsPerLine;
          this.loginType = this.field.settings.specific.loginType;
          this.additionalLoginTypes =
            this.field.settings.specific.additionalLoginTypes;
          this.parentDateField = this.field.settings.specific.parentDateField;

          this.parentFieldsDays = this.field.settings.specific.parentFieldsDays;

          this.masterFormTableColumns =
            this.field.settings.specific.masterFormTableColumns || [];

          this.masterTableColumnsCopy =
            this.field.settings.specific.masterFormTableColumns || [];

          this.parentOptionField =
            this.field.settings.specific.parentOptionField;

          this.dateFieldOptionSettings =
            this.field.settings.specific.dateFieldOptionSettings || [];

          this.isSearchField = this.field.settings.specific.isSearchField;
          this.hasSameForm = this.field.settings.specific.hasSameForm;
          this.searchFormId = this.field.settings.specific.searchFormId;

          this.masterFormSyncSettings =
            this.field.settings.specific.masterFormSyncSettings || [];

          this.masterSyncField = this.field.settings.specific.masterSyncField;
          this.formSyncField = this.field.settings.specific.formSyncField || [];

          // ...

          // validation settings

          this.fieldRule = this.field.settings.validation.fieldRule;
          this.contentRule = this.field.settings.validation.contentRule;
          this.minimum = this.field.settings.validation.minimum;
          this.maximum = this.field.settings.validation.maximum;
          this.allowedFileTypes =
            this.field.settings.validation.allowedFileTypes;
          this.maxFileSize = this.field.settings.validation.maxFileSize;
          this.enableSettings =
            this.field.settings.validation.enableSettings || [];

          this.dateRange = this.field.settings.validation.dateRange;
          this.timeRange = this.field.settings.validation.timeRange;
          this.maxiDays = this.field.settings.validation.maxiDays;
          this.miniDays = this.field.settings.validation.miniDays;

          this.maxiTime = this.field.settings.validation.maxiTime;
          this.miniTime = this.field.settings.validation.miniTime;

          this.assignOtherControls =
            this.field.settings.validation.assignOtherControls;

          this.documentExpiryField =
            this.field.settings.validation.documentExpiryField || "";

          this.mandatorySettings =
            this.field.settings.validation.mandatorySettings || [];
          if (this.field.settings.validation.answerIndicator) {
            this.answerIndicator =
              this.field.settings.validation.answerIndicator;
          } else {
            this.answerIndicator = "NO";
          }

          this.readonlySettings =
            this.field.settings.validation.readonlySettings || [];

          this.timeFormat = this.field.settings.validation.timeFormat || "12";
          this.rangeType = this.field.settings.validation.rangeType;
          this.maximumNumberField =
            this.field.settings.validation.maximumNumberField;
          this.minimumNumberField =
            this.field.settings.validation.minimumNumberField;

          // ...

          // ai settings

          if (this.field.settings.aiSettings) {
            this.formControlValidate =
              this.field.settings.aiSettings.formControlValidate;

            if (this.field.settings.aiSettings.imageFileValidate) {
              this.imageFileValidate =
                this.field.settings.aiSettings.imageFileValidate;
            }

            if (this.field.settings.aiSettings.fileDataValidate) {
              this.fileDataValidate =
                this.field.settings.aiSettings.fileDataValidate;
            }
          } else {
            this.formControlValidate = {
              optionsType: "",
              masterFormId: 0,
              masterFormColumn: [],
              formFields: "",
              existingFormFields: [],
              conditionFields: [],
            };
            (this.fileDataValidate = []),
              (this.imageFileValidate = {
                optionsType: "",
                formFields: [],
              });
          }

          // ...
        }
      },
    },

    masterFormParentColumn() {
      let panelFields = [];
      for (const panel of this.panels) {
        panelFields.push(...panel.fields);
      }
      if (this.defaultValue === "MASTER") {
        let master = panelFields.find(
          (item) => item.id === this.masterFormParentColumn
        );

        if (master) {
          if (master.settings.specific.masterFormId) {
            this.masterFormId = master.settings.specific.masterFormId;
            this.getFormFields();
          }
        } else {
          if (this.settingsFor === "TABLE" && this.tableFieldId) {
            let hasMaster = panelFields.find(
              (item) => item.id === this.tableFieldId
            );

            if (hasMaster) {
              let table = hasMaster.settings.specific.tableColumns.find(
                (column) => column.id === this.masterFormParentColumn
              );

              if (table) {
                if (table.settings.specific.masterFormId) {
                  this.masterFormId = table.settings.specific.masterFormId;
                  this.getFormFields();
                }
              }
            }
          }
        }
      }
    },

    minimum() {
      if (this.minimum) {
        this.dateError = "";
        this.timeError = "";
      }
    },

    maximum() {
      if (this.maximum) {
        this.dateError = "";
        this.timeError = "";
      }
    },
  },

  mounted() {
    if (["LOGIN_AUTHENTICATION"].includes(this.field.type)) {
      this.activeTab = "SPECIFIC";
    }

    if (this.field.showSettingsOnly == false) {
      this.activeTab = "SPECIFIC";
      this.tabs = [
        {
          id: this.$nano.id(),
          label: "specific",
          value: "SPECIFIC",
          icon: "mdi-code-tags-check",
        },
      ];
    }

    if (this.field.type == "FILE_UPLOAD") {
      this.tabs.push({
        id: this.$nano.id(),
        label: "AI settings",
        value: "AI_DATAOPS",
        icon: "mdi-auto-fix",
      });
    }
  },

  methods: {
    closeSettings() {
      this.$emit("input", false);
    },

    async save() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      if (this.field.type === "DATE" || this.field.type === "TIME") {
        if (
          this.dateRange === "DATE_RANGE" ||
          this.timeRange === "TIME_RANGE"
        ) {
          if (!this.minimum && !this.maximum) {
            if (this.dateRange === "DATE_RANGE") {
              this.dateError = "Select any date maximum or minimum";
            } else {
              this.timeError = "Select any time maximum or minimum";
            }
            return;
          } else {
            this.dateError = "";
            this.timeError = "";
          }
        }
      }

      const field = cloneDeep(this.field);

      // general settings

      field.settings.general.hideLabel = this.hideLabel;
      field.label = this.label;
      field.settings.general.size = this.size;
      field.settings.general.visibility = this.visibility;
      field.settings.general.placeholder = this.placeholder;
      field.settings.general.tooltip = this.tooltip;
      field.settings.general.dividerType = this.dividerType;
      field.settings.general.url = this.url;

      // ...

      // specific settings

      field.settings.specific.optionsType = this.optionsType;
      field.settings.specific.masterFormId = this.masterFormId;
      field.settings.specific.masterFormColumn = this.masterFormColumn;
      field.settings.specific.masterFormParentColumn =
        this.masterFormParentColumn;
      field.settings.specific.repositoryId = this.repositoryId;
      field.settings.specific.repositoryField = this.repositoryField;
      field.settings.specific.repositoryFieldParent =
        this.repositoryFieldParent;
      field.settings.specific.predefinedTable = this.predefinedTable;
      field.settings.specific.predefinedTableColumn =
        this.predefinedTableColumn;
      field.settings.specific.customOptions = this.customOptions;
      field.settings.specific.separateOptionsUsing = this.separateOptionsUsing;
      field.settings.specific.allowToAddNewOptions = this.allowToAddNewOptions;
      field.settings.specific.optionsPerLine = this.optionsPerLine;
      field.settings.specific.defaultValue = this.defaultValue;
      field.settings.specific.customDefaultValue = this.customDefaultValue
        ? this.customDefaultValue
        : this.getCustomDefaultValue;
      field.settings.specific.autoGenerateValue = this.autoGenerateValue;
      field.settings.specific.showColumnTotal = this.showColumnTotal;
      field.settings.specific.allowMultipleFiles = this.allowMultipleFiles;
      field.settings.specific.ratingIcon = this.ratingIcon;
      field.settings.specific.ratingIconCount = this.ratingIconCount;
      field.settings.specific.allowHalfRating = this.allowHalfRating;
      field.settings.specific.allowMultipleSignatures =
        this.allowMultipleSignatures;

      field.settings.specific.tableColumns = this.tableColumns;
      field.settings.specific.tableRowChooseType = this.tableRowChooseType;
      field.settings.specific.tableRowsType = this.tableRowsType;
      field.settings.specific.tableFixedRowCount = this.tableFixedRowCount;
      field.settings.specific.qrValue = this.qrValue;
      field.settings.specific.matrixColumns = this.matrixColumns;
      field.settings.specific.matrixRows = this.matrixRows;
      field.settings.specific.matrixType = this.matrixType;
      field.settings.specific.matrixTypeSettings = this.matrixTypeSettings;
      field.settings.specific.fibFields = this.fibFields;
      field.settings.specific.tabList = this.tabList;
      field.settings.specific.popupTriggerType = this.popupTriggerType;
      field.settings.specific.secondaryPanel = this.secondaryPanel;
      field.settings.specific.mappedFieldId = this.mappedFieldId;
      field.settings.specific.mappedColumnId = this.mappedColumnId;
      field.settings.specific.nestedListMaxLevel = this.nestedListMaxLevel;
      field.settings.specific.nestedListFieldType = this.nestedListFieldType;
      field.settings.specific.nestedListTypeSettings =
        this.nestedListTypeSettings;
      field.settings.specific.nestedListItemsPerLine =
        this.nestedListItemsPerLine;
      field.settings.specific.loginType = this.loginType;
      field.settings.specific.additionalLoginTypes = this.additionalLoginTypes;

      if (this.defaultValue === "PARENT_FIELD") {
        field.settings.specific.parentDateField = this.parentDateField;
        field.settings.specific.parentFieldsDays = this.parentFieldsDays;

        this.parentOptionField = "";
        this.dateFieldOptionSettings = [];
      }

      field.settings.specific.parentOptionField = this.parentOptionField;

      if (this.masterFormTableColumns.length) {
        if (this.masterFormTableColumns[0].masterColumn) {
          field.settings.specific.masterFormTableColumns =
            this.masterFormTableColumns;
        } else {
          field.settings.specific.masterFormTableColumns = [];
        }
      } else {
        field.settings.specific.masterFormTableColumns = [];
      }
      if (this.defaultValue === "PARENT_FIELD_OPTIONS") {
        if (this.dateFieldOptionSettings.length) {
          if (this.dateFieldOptionSettings[0].option) {
            field.settings.specific.dateFieldOptionSettings =
              this.dateFieldOptionSettings;
          } else {
            field.settings.specific.dateFieldOptionSettings = [];
          }
        } else {
          field.settings.specific.dateFieldOptionSettings = [];
        }
        this.parentDateField = "";
        this.parentFieldsDays = 0;
      }

      field.settings.specific.isSearchField = this.isSearchField;
      field.settings.specific.hasSameForm = this.hasSameForm;
      field.settings.specific.searchFormId = this.searchFormId;

      if (this.hasSameForm === "NO" && this.searchFormId) {
        if (this.masterFormSyncSettings.length) {
          if (this.masterFormSyncSettings[0].formField) {
            field.settings.specific.masterFormSyncSettings =
              this.masterFormSyncSettings;
          } else {
            field.settings.specific.masterFormSyncSettings = [];
          }
        } else {
          field.settings.specific.masterFormSyncSettings = [];
        }
      }

      field.settings.specific.masterSyncField = this.masterSyncField;
      field.settings.specific.formSyncField = this.formSyncField;

      // ...

      // validation settings

      field.settings.validation.fieldRule = this.fieldRule;
      field.settings.validation.contentRule = this.contentRule;
      field.settings.validation.minimum = this.minimum;
      field.settings.validation.maximum = this.maximum;
      field.settings.validation.allowedFileTypes = this.allowedFileTypes;
      field.settings.validation.maxFileSize = this.maxFileSize;
      field.settings.validation.dateRange = this.dateRange;
      field.settings.validation.timeRange = this.timeRange;
      field.settings.validation.maxiDays = this.maxiDays;
      field.settings.validation.miniDays = this.miniDays;
      field.settings.validation.maxiTime = this.maxiTime;
      field.settings.validation.miniTime = this.miniTime;

      field.settings.validation.assignOtherControls = this.assignOtherControls;
      field.settings.validation.documentExpiryField = this.documentExpiryField;

      if (this.enableSettings.length) {
        if (this.enableSettings[0].value) {
          field.settings.validation.enableSettings = this.enableSettings;
        } else {
          field.settings.validation.enableSettings = [];
        }
      } else {
        field.settings.validation.enableSettings = [];
      }

      if (this.mandatorySettings.length) {
        if (this.mandatorySettings[0].value) {
          field.settings.validation.mandatorySettings = this.mandatorySettings;
        } else {
          field.settings.validation.mandatorySettings = [];
        }
      } else {
        field.settings.validation.mandatorySettings = [];
      }
      field.settings.validation.answerIndicator = this.answerIndicator;

      if (this.readonlySettings.length) {
        if (this.readonlySettings[0].value) {
          field.settings.validation.readonlySettings = this.readonlySettings;
        } else {
          field.settings.validation.readonlySettings = [];
        }
      } else {
        field.settings.validation.readonlySettings = [];
      }

      if (this.timeFormat) {
        field.settings.validation.timeFormat = this.timeFormat;
      }
      if (this.rangeType) {
        field.settings.validation.rangeType = this.rangeType;
        field.settings.validation.maximumNumberField = this.maximumNumberField;
        field.settings.validation.minimumNumberField = this.minimumNumberField;
      }

      // ...

      if (!field.settings.aiSettings) {
        this.$set(field.settings, "aiSettings", {
          formControlValidate: {
            optionsType: "",
            masterFormId: 0,
            masterFormColumn: [],
            formFields: "",
            existingFormFields: [],
            conditionFields: [],
          },
          fileDataValidate: [],
          imageFileValidate: {
            optionsType: "",
            formFields: [],
          },
        });
      }
      if (!isEmpty(this.formControlValidate)) {
        field.settings.aiSettings.formControlValidate =
          this.formControlValidate;
      } else {
        // field.settings.aiSettings.formControlValidate = {};
      }

      if (!isEmpty(this.imageFileValidate)) {
        field.settings.aiSettings.imageFileValidate = this.imageFileValidate;
      } else {
        // field.settings.aiSettings.imageFileValidate = {};
      }

      field.settings.aiSettings.fileDataValidate = this.fileDataValidate;

      // ...

      this.$emit("save", field);
      this.closeSettings();
    },

    showFibFieldSettings(fibField) {
      this.$emit("fib-settings", fibField);
    },

    showColumnSettings(column) {
      this.$emit("column-settings", column);
    },

    showMatrixTypeSettings() {
      this.$emit("matrix-type-settings");
    },

    showNestedListTypeSettings() {
      this.$emit("nested-list-type-settings");
    },

    async getFormFields() {
      this.masterFormColumns = [];
      const { error, payload } = await form.getForm(this.masterFormId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            // console.log(field);
            if (this.field.type === "TABLE") {
              if (field.type === "TABLE") {
                // console.log(field);
                field.settings.specific.tableColumns.forEach((table) => {
                  this.masterFormColumns.push({
                    id: this.$nano.id(),
                    label: table.label ? table.label + " (TABLE)" : table.type,
                    value: table.id,
                  });
                });
              } else {
                this.masterFormColumns.push({
                  id: this.$nano.id(),
                  label: field.label ? field.label + " (Control)" : field.type,
                  value: field.id,
                });
              }
            } else {
              this.masterFormColumns.push({
                id: field.id,
                label: field.label,
                value: field.id,
              });
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#field-settings {
  .field {
    margin: 16px 16px 24px 16px;
  }

  .content {
    flex: 1;
    padding-bottom: 36px;
    border-right: 1px solid var(--divider-color);
  }

  .tabs {
    width: 68px;
    height: 100%;
    height: calc(100vh - 112px);
    padding: 16px;
  }
}
</style>
